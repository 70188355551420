
.phishing-container {
    display: flex;
    overflow: hidden;
    position: relative;
    justify-content: flex-end;
    background-color: #28dbd1;
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: 2px;
    padding: 10px;
  }
  .phishing-warning-text {
    margin-right: auto;
    margin-left: auto;
    padding-left: 30px;
    z-index: 10;
  }
  
  .phishing-button {
    font-size: 20px;
    padding: 10px 20px;
    color: #fff;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .phishing-button:hover {
    color: #000;
  }
  