/* FeaturedLink.module.css */
.featured-link-container {
  display: flex;
  align-items: center;
  border: 1px solid #28dbd1;;
  border-radius: 10px;
  padding: 10px;
  background-color: #0a1f2f;;
  transition: background-color 0.3s;
  text-decoration: none;
  color: #333;
  width: 300px; /* Adjust the width as needed */
}

.featured-link-container:hover {
  background-color: #0a1f2f;;
}

.featured-link-image {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px; /* Add some spacing between image and text */
}

.featured-link-content {
  display: flex;
  flex-direction: column;
}

.featured-link-title {
  font-weight: bold;
  margin-right: 10px;
  margin-bottom: 2px;
  color: #fff;
}

.featured-link-multiplier {
  font-weight: 600 !important;
  color: rgb(186 132 255/var(--tw-text-opacity));
  background-image: linear-gradient(90deg,#0bbdda 0,#28dbd1 39%,#b56388) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
