
.lottery-winners {
  font-family: Arial, sans-serif;
  color: #fff;
  /*
  background-color: #1a202c;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  */
  border-radius: 8px;
  padding: 16px;
  
}

.lottery-winners__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.lottery-winners__search {
  margin-bottom: 16px;
}

.lottery-winners__search-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  /*
  color: #fff;
  background-color: #2d3748;
  */
  border: none;
  border-radius: 4px;
  outline: none;
  
  background-color: rgb(2, 18, 29);
border-color: rgb(2, 18, 29);
color: rgb(193, 199, 203);
padding-inline-end: 10px;
}

.lottery-winners__list {
  height: 200px;
  overflow-y: auto;
}

.lottery-winners__entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  font-size: 14px;
  border-bottom: 1px solid #4a5568;
}

.lottery-winners__entry--chance {
  /*
  background-color: #4caf50;
  */
}

.lottery-winners__entry--prospect {
  background-color: #2196f3;
}

.lottery-winners__address {
  font-family: monospace;
  font-size: 12px;
}

.lottery-winners__label {
  text-transform: uppercase;
  font-weight: bold;
}
