

.project__item-description {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.project__item-description p {
  margin-bottom: 0;
}

.project__infolist-name {
  font-weight: 1;
  margin-bottom: 5px;
}

.project__infolist-data {
  margin-bottom: 0;
}
.project__item-middle p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines to show */
}


.social-media-list {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.social-media-list ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.social-media-list ul li {
  margin-right: 10px;
}

.social-media-list ul li:last-child {
  margin-right: 0;
}

.social-media-list ul li a {
  color: #fff;
  font-size: 24px;
}

.social-media-list ul li a:hover {
  color: #28dbd1;
}









.project__item {
  flex-wrap: wrap;
  justify-content: space-between;
  border: 2px solid #28dbd1;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.project__item-left {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-right: 20px;
}


.project__item-author h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.project__item-author h4 i {
  margin-right: 5px;
}

.project__item-description {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #fff;
}

.project__item-right {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.badge {
  display: inline-block;
  background-color: #0a1f2f;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 15px;
  text-transform: uppercase;
  font-weight: 700;
}















.social-media-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-media-list ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-media-list li {
  margin-right: 10px;
}

.project__item-right {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.badge {
  background-color: #6c757d;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 8px;
  border-radius: 4px;
}
