
.buy-chart-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  background-color: rgba(40,219,209,.2);
  text-align: center;
  border-radius: 1rem !important;
  padding: 10px;
  color: #fff;
}

.buy-chart-bg {
  /*
  background-color: #000;
  opacity: 0.5;
  border-radius: 0.5rem;
  padding: 0.75rem;
  */
}

.buy-chart-flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy-chart-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  border: none;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.buy-chart-btn-buy {
  border: 1px solid #28dbd1;
  border-radius: 0.25rem;
}

.buy-chart-icon {
  height: 1.5rem;
  width: auto;
  margin-right: 0.5rem;
}

.buy-chart-dropdown {
  position: relative;
}

.buy-chart-btn-chart {
  position: relative;
  padding-right: 2rem;
  border: 1px solid #28dbd1;
  border-radius: 0.25rem;
}

.buy-chart-text {
  margin-left: 0.5rem;
}

.buy-chart-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background-color: #111;
  border-radius: 0.25rem;
  overflow: hidden;
  padding: 0.5rem 0;
}

.buy-chart-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: #fff;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s ease-in-out;
}

.buy-chart-menu-item:hover {
  background-color: #222;
}


.buy-chart-btn-chart::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.3rem 0.3rem 0 0;
  border-color: #fff transparent transparent transparent;
}

.buy-chart-dropdown-menu.hidden {
  display: none;
}








