.external-wallet-form {
  margin-bottom: 8px;
}

.external-wallet-bg {
  background-color: rgba(40,219,209,.2);
  padding: 1rem;
  border-radius: 0.5rem;
  position: relative;
}

.external-wallet-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.external-wallet-group {
  /*
  margin-bottom: 1.5rem;
  */
}

.external-wallet-label {
  font-weight: bold;
  color: #fff;
  font-size: 13px;
}

.external-wallet-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background-color: rgb(2, 18, 29);
  border-color: rgb(2, 18, 29);
  color: rgb(193, 199, 203);
  padding-inline-end: 10px;
}

.external-wallet-feedback {
  color: red;
}

.external-wallet-text {
  color: #c1c7cb;
  font-weight: normal;
}

.external-wallet-check {
  display: flex;
  align-items: center;
}

.external-wallet-checkbox {
  margin-right: 0.5rem;
}

.external-wallet-check-label {
  font-size: 0.875rem;
  color: #fff;

}

.external-wallet-button {
  margin: 0 auto;
  display: block;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}


.external-wallet-text a {
  color: #28dbd1;
}
.external-wallet-text a:hover {
  color: #e60669 !important;
  text-decoration: none;
}


.external-wallet-check {
  display: flex;
  align-items: center;
}

.external-wallet-checkbox {
  margin-right: 0.5rem;
  align-self: flex-start; /* Align the checkbox to the top */
}

.external-wallet-check-label {
  font-size: 0.875rem;
  flex: 1; /* Allow the label to grow and fill the remaining space */
}


.external-wallet-input.invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.external-wallet-input:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + .75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.external-wallet-input:focus {
  color: #f8f9fa;
  background-color: #212529;
  border-color: #495057;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.external-wallet-input:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.external-wallet-input:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
