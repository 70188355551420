.avatar-custom {
  position: relative;
  margin-right: 20px;
}
.avatar-custom img {
  max-width: 100%;
  height: auto;
}
.bsc-icon {
  position: absolute;
  bottom: -10px;
  right: -10px;
  max-width: 30px;
  height: auto;
}
.details-custom {
  flex-grow: 1;
}
.name-custom {
  margin: 0;
  font-size: 24px;
}
.currency-custom {
  font-size: 16px;
  color: #666;
}


.container-custom {
  display: flex;
  align-items: center;
  padding: 1rem;
  /*background-color: #F7FAFC;*/
  border-radius: 1rem;
  /*
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  width: 500px;
  */
}

.avatar-custom {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;
}

.avatar-custom img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details-custom {
  display: flex;
  flex-direction: column;
}

.name-custom {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: bold;
}

.currency-custom {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
  margin-bottom: 0.5rem;
}




















/*
color: rgb(229 229 229/var(--tw-text-opacity)) !important;


.text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68/var(--tw-text-opacity)) !important;
}
*/





.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.flex-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.image-container {
  width: 45px;
  height: 45px;
  margin-right: 1rem;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0;
}

.icon {
  height: 1.5rem !important;
  width: auto;
}




.social-media-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media-icons a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.social-media-icons a:hover {
  background-color: #3b5998; /* change to desired hover color */
}

.social-media-icons a i {
  font-size: 20px;
  color: #3b5998; /* change to desired icon color */
}







.sale-message {
  background-color: rgba(40,219,209,.2);
  text-align: center;
  margin-bottom: 8px;
  border-radius: 1rem !important;
  /*
  padding: .75rem .75rem 0 .75rem;
  */
  color: #fff;
}
.sale-message p {
  font-size: 20px;
}

.kyc-message {
  background-color: rgba(40,219,209,.2);
  text-align: center;
  margin-bottom: 8px;
  border-radius: 1rem !important;
  padding: 10px;
  color: #fff;
}
.kyc-message span {

}



.metamask-button {
  padding: 4px 8px;
  color: #28dbd1;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: all .3s ease;
border: 1px solid rgba(40,219,209,.4);
place-items: center;
border-radius: 5px;
background-color: transparent;
height: 35px;
}

.metamask-button:hover {
  background-color: #28dbd1;
  color: #02121d;
}


.metamask-icon {
  height: 18px;
  margin-right: 6px;
}












.pool-details {
  /*
  background-color: #F3F4F6;
  margin-bottom: 16px;
  */
  border-radius: 8px;
  padding: 16px;
  
}



.pool-details-body {
  background-color: rgba(40,219,209,.2);
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  text-align: center;
  color:#fff;
}

.pool-details-body strong {
  font-weight: 700 !important;
}

.pool-details-footer {
  text-align: right;
}

.pool-details-footer a {
  color: #6B7280;
  text-decoration: underline;
}












.pool-details-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.pool-details-header h2 {
  margin: 0;
}


.card1-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  border-radius: 1rem !important;
  padding: .75rem .75rem 0 .75rem;
  background-color: rgba(40,219,209,.2);
  color: #fff;
}

.card1-header b {
  margin: 0;

}












.event-card {
  /*
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  */
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  
  margin-bottom: 20px;
}

.event-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.event-icon {
  margin-right: 10px;
}

.event-title {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
  margin: 0;
}

.event-details {
  border-collapse: collapse;
  font-family: rajdhani,sans-serif;
color: #fff;
line-height: 1.25;
font-weight: 700;
}

.event-details-label {
  padding-right: 10px;
  font-size: .875rem !important;
line-height: 1.25rem !important;
text-align: left;
}

.event-details-value {
  padding-right: 10px;
  font-size: .875rem !important;
line-height: 1.25rem !important;
text-align: left;
}







.mb_details{
  margin-bottom: 1rem !important;
}

  .mb_details a{
    color: #28dbd1;
    }

  

    .projects-details-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 20px;
      padding: 5px 0 5px 0;
    }
  
    .projects-details-heading {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      color: #28dbd1;
      text-transform: uppercase;
    }
  
    .projects-details-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  
    .projects-details-item {
      display: flex;
      align-items: center;
    }
    
    .projects-details-label {
      font-weight: bold;
      margin-right: 0.5rem;
    }
  
    .projects-details-value {
      color: #c1c7cb;
      font-weight: normal;
    }




    .price-container {
      display: flex;
      align-items: center;
    }
    
    .price-links {
      display: flex;
      align-items: center;
      margin-left: 1rem;
    }
    
    .price-link-image {
      height: 1.5rem;
      margin-right: 0.5rem;
    }






    .protection-note {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      color: #28dbd1;
      text-transform: uppercase;
    }