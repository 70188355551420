body{
  background: #e9eaed;
  font-family: 'Oswald', sans-serif;
}

.App{
  width: 90%;
  margin: 0 auto;
}


.text-white {
  color: rgb(255 255 255/var(--tw-text-opacity)) !important;
}
.text-gray-400, .text-white {
  --tw-text-opacity: 1 !important;
}

.rounded-lg {
  border-radius: .5rem !important;
}
.items-center {
  align-items: center !important;
}
.flex {
  display: flex !important;
}

.leading-tight {
  line-height: 1.25 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}
.flex-col {
  flex-direction: column !important;
}
.flex {
  display: flex !important;
}
.mr-3 {
  margin-right: .75rem !important;
}


.btn .badge {
  position: relative;
  top: -1px;
}
.UserWallet_address {
  background: linear-gradient(90deg,#000,#000);
  top: 0 !important;
}
.UserWallet_address {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .25rem .75rem;
  font-weight: 400;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
}
.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}