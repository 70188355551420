.my-account-card-body {
  background-color: #0a1f2f;
  border-radius: 5px;
  padding: 20px;
  color: #fff;
}

.my-account-heading {
  margin-bottom: 20px;
  color: #eee;
}

.my-account-wallet {
  margin-bottom: 20px;
}

.my-account-wallet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-account-wallet-title {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.my-account-btn {
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.my-account-btn-icon {
  height: 16px;
  width: 16px;
  vertical-align: middle;
}

.my-account-wallet-info {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.my-account-wallet-label {
  font-weight: bold;
  margin-right: 5px;
}

.my-account-wallet-address {
  color: #fff;
  text-decoration: none;
  word-break: break-all;
}

.my-account-wallet-link {
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #fff;
  text-decoration: none;
}

.my-account-wallet-link-icon {
  height: 16px;
  width: 16px;
  margin-top: -1px;
  margin-left: 1px;
}
.my-account-wallet-not-specified {
  font-style: italic;
}













/* Styles for the MyComponent container */
.my-tier-container {
  display: flex;
  flex-direction: column;
  background-color: #0a1f2f;
border-radius: 5px;
padding: 20px;
color: #fff;
}

/* Styles for the MyComponent card */
.my-tier-card {
  margin-bottom: 20px;
}

/* Styles for the card body */
.my-tier-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styles for the tier title */
.my-tier-title {
  color: #ffffff;
  margin-bottom: 10px;
}

/* Styles for the tier subtitle */
.my-tier-subtitle {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0;
}

/* Styles for the tier flex container */
.my-tier-flex-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

/* Styles for the tier button */
.my-tier-button {
  display: flex;
  align-items: center;
  background-color: rgba(40,219,209,.2);
  border: none;
  color: #ffffff;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.my-tier-button:hover {
  background-color: #333333;
}

/* Styles for the tier address */
.my-tier-address {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Styles for the tier copied text */
.my-tier-copied {
  display: none;
  color: #33cc33;
  font-size: 12px;
}

/* Styles for the tier button when copied */
.my-tier-button.copied .my-tier-copied {
  display: inline;
}

/* Styles for the tier link */
.my-tier-link {
  margin-left: 10px;
  color: #ffffff;
  text-decoration: none;
}

/* Styles for the tier SVG */
.my-tier-svg {
  width: 20px;
  height: 20px;
  fill: #ffffff;
}

/* Styles for the tier content */
.my-tier-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styles for the tier info */
.my-tier-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

/* Styles for the tier info text */
.my-tier-info-text {
  color: #ffffff;
}

/* Styles for the tier stake text */
.my-tier-stake-text {
  color: #ffffff;
  margin: 10px 0;
  text-align: center;
}

/* Styles for the tier progress bar */
.my-tier-progress-bar {
  width: 100%;
  height: 10px;
  background-color: #333333;
  border-radius: 5px;
}

/* Styles for the tier progress bar fill */
.my-tier-progress-bar-fill {
  height: 100%;
  background-color: #33cc33;
  border-radius: 5px;
}

/* Styles for the tier chance info */
.my-tier-chance-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

/* Styles for the tier chance text */
.my-tier-chance-text {
  color: #ffffff;
}

/* Styles for the tier action */
.my-tier-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

/* Styles for the tier action text */
.my-tier-action-text {
  color: #ffffff;
}

/* Styles for the tier action button */
.my-tier-action-button {
  background-color: #1a1a1a;
  border: none;
  color: #ffffff;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.my-tier-action-button:hover {
  background-color: #333333;
}

/* Styles for the tier action link */
.my-tier-action-link {
  color: #ffffff;
  text-decoration: none;
}
