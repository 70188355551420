.table {
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  margin-bottom: 1rem;
}

.table th, .table td {
  /*
  padding: 0.75rem;
  */
  text-align: center;
  vertical-align: middle;
  /*
  border: 1px solid #dee2e6;
  */
}

.table th {
  font-weight: bold;
  /*
  background-color: #6c757d;
  */
  color: #fff;
}

.caption {
  caption-side: top;
  font-size: 12px;
  text-align: center;
  background-color: #343a40;
  color: #28dbd1;
  padding: 0.25rem 0;
  opacity: 0.5;
}

.brand-text {
  font-weight: bold;
  color: #0a97ff;
}

.brand-text.font-normal {
  font-weight: normal;
  color: #000;
}

.table td div {
  margin: 0 auto;
  width: 80%;
  text-align: center;
}
.table td  {
  color: #fff;
}