


.dh-item {
  flex: 1 1 0;
}

.header-left {
  text-align: left;
}

.myIcon {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid black;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
}

.rbc-event{
  background-color: #28dbd1;
  color: #02121d;
}

.rbc-off-range-bg {
  background: #5c5353;
}
/*
.rbc-today {
  background-color: #00ffe1;
  color:#fff;
}
*/

.rbc-toolbar .rbc-toolbar-label {
  color: #fff;
}

.rbc-calendar {
  color: #fff;
}
.rbc-toolbar button {
  color: #fff;
}