.badges-group {
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
}

.badge-group {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 75%;
  border-radius: 10rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
}

.badge-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.badge-success {
  background-color: #28a745;
  border-color: #28a745;
}

.badge-warning {
  background-color: #9f7b10;
  border-color: #9f7b10;
}
.badge-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}
.badge-icon {
  fill: currentColor;
  margin-right: 0.25rem;
}
